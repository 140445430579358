import ApiService from "@/core/services/api.service";
import { toSafeInteger, isArray, filter, map } from "lodash";

// action types

// mutation types
export const SET_LINEITEMS = "setLineItems";
export const SET_PRODUCT = "setProduct";
export const SET_SERVICE = "setService";

export const SET_ERROR = "setError";

const state = {
	errors: [],
	all_products: [],
	all_services: [],
};

const getters = {
	all_products(state) {
		return state.all_products;
	},
	all_services(state) {
		return state.all_services;
	},
};

const actions = {
	[SET_LINEITEMS](context, params) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.query(`listing/all`, params)
				.then(({ data }) => {
					const all_product_list = map(data.list, (row) => {
						return { ...row, value: row.id, text: `${row.barcode} - ${row.name}` };
					});
					const product_data = filter(all_product_list, (row) => {
						return row.product_type == "inventory";
					});
					const service_data = filter(all_product_list, (row) => {
						return row.product_type == "service";
					});
					context.commit(SET_PRODUCT, product_data);
					context.commit(SET_SERVICE, service_data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		if (error && isArray(error)) {
			state.errors = error.map((row) => {
				row.timeout = toSafeInteger(process.env.VUE_APP_MESSAGE_TIMEOUT);
				return row;
			});
		} else {
			state.errors = [];
		}
	},
	[SET_PRODUCT](state, data) {
		state.all_products = data;
	},
	[SET_SERVICE](state, data) {
		state.all_services = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
