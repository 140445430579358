import {
	toSafeInteger,
	isObject,
	isEmpty,
	capitalize,
	includes,
	toLower,
	lowerCase,
	identity,
	pickBy,
	cloneDeep,
} from "lodash";
import { formatMoney } from "accounting-js";
import { SET_ERROR } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
import MomentJS from "moment-timezone";
import ObjectPath from "object-path";
import { ValidateBarcode, GenrateBarcode } from "@/core/lib/common.lib";
import { uuid as UUID } from "vue-uuid";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

const validateEmail = (email) => {
	//eslint-disable-next-line
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

class MainSetting {
	install(Vue) {
		Vue.mixin({
			data() {
				return {
					barcodeError: null,
					barcodeLoading: false,
					today: null,
					uniqFieldId: null,
					listTitle: [
						{
							value: "mr",
							text: "Mr.",
						},
						{
							value: "ms",
							text: "Ms.",
						},
						{
							value: "mrs",
							text: "Mrs.",
						},
						{
							value: "miss",
							text: "Miss.",
						},
						{
							value: "dr",
							text: "Dr.",
						},
					],
					listAccounting: [
						{
							value: "none",
							text: "NONE",
						},
						{
							value: "fifo",
							text: "FIFO (First In First Out)",
						},
						{
							value: "fefo",
							text: "FEFO (First Expire First Out)",
						},
						{
							value: "lifo",
							text: "LIFO (Last In First Out)",
						},
					],
					listRadioCondition: [
						{
							label: "Yes",
							value: true,
						},
						{
							label: "No",
							value: false,
						},
					],
					durationList: [
						{
							text: "Day",
							value: "day",
						},
						{
							text: "Week",
							value: "week",
						},
						{
							text: "Month",
							value: "month",
						},
					],
					vrules: {
						required(val, field, isInteger) {
							if (isObject(val) && isEmpty(val)) {
								return `${field} is required`;
							}
							if (isInteger && parseInt(val) <= 0) {
								return `${field} is required.`;
							}
							return !!val || `${field} is required`;
						},
						requiredFile(file, field) {
							if (!file) {
								return `${field} is required`;
							}
							if (file && file.size > 0) {
								return true;
							}
						},
						validateQty(val, maxQty, field) {
							let newQty = toSafeInteger(val);
							let newAvailable = toSafeInteger(maxQty);
							if (newQty > newAvailable) {
								return `${field} is must be less than or equal to ${newAvailable}.`;
							}
							return true;
						},
						validEmail(val, field) {
							if (val) {
								if (validateEmail(val)) {
									return true;
								}
								return `${field} must be valid`;
							}
							return true;
						},
						validFloat(val, field) {
							if (val) {
								if (/^\d*\.?\d*$/.test(val)) {
									return true;
								}
								return `${field} must be valid`;
							}
							return true;
						},
						validNumeric(val, field) {
							if (val) {
								if (/^\d+$/.test(val)) {
									return true;
								}
								return `${field} must be valid (only digits) & greater than 0`;
							}
							return true;
						},
						maxLength(val, field, maxLen) {
							if (val) {
								val = val.toString();
								if (val.length <= maxLen) {
									return true;
								}
								return `${field} length must be less than or equal to ${maxLen}`;
							}
							return true;
						},
						minLength(val, field, minLen) {
							if (val) {
								val = val.toString();
								if (val.length >= minLen) {
									return true;
								}
								return `${field} length must be greater than or equal to ${minLen}`;
							}
							return true;
						},
						phoneNumber(val, field, minLen) {
							if (val) {
								val = val.toString();
								if (val.length == minLen) {
									return true;
								}
								return `Enter valid ${field}`;
							}
							return true;
						},
						lessThan(val, field, len) {
							if (val) {
								val = parseInt(val);
								if (val <= len) {
									return true;
								}
								return `${field} must be less than or equal to ${len}`;
							}
							return true;
						},
						greaterThan(val, field, minLen) {
							if (val) {
								val = val.toString();
								if (val.length >= minLen) {
									return true;
								}
								return `${field} length must be greater than or equal to ${minLen}`;
							}
							return true;
						},
						deleteValidation(val, field, barcode) {
							if (val) {
								val = val.toString();
								barcode = barcode.toString();
								if (val === barcode) {
									return true;
								}
								return `${field} must be equal to ${barcode}`;
							}
							return true;
						},
						confirmPassword(val, field, old) {
							if (val) {
								val = val.toString();
								old = old.toString();
								if (val === old) {
									return true;
								}
								return `${field} you entered don't match to new password`;
							}
							return true;
						},
					},
				};
			},
			methods: {
				ObjectKey(object, key, def) {
					return ObjectPath.get(object, key, def);
				},
				ObjectHasKey(object, key) {
					return ObjectPath.has(object, key);
				},
				goBackStepper() {
					this.stepper--;
				},
				deepEmpty(param) {
					return cloneDeep(pickBy(param, identity));
				},
				formatDateTime(dateTime) {
					return MomentJS(dateTime).format("DD/MM/YYYY hh:mm a");
				},
				formatDateTimeRaw(dateTime) {
					return MomentJS(dateTime).format("YYYY-MM-DD HH:mm");
				},
				formatDate(date) {
					return MomentJS(date).format("DD/MM/YYYY");
				},
				formatDateRaw(date) {
					return MomentJS(date).format("YYYY-MM-DD");
				},
				formatMoney(money) {
					return formatMoney(money, {
						symbol: "$",
						decimal: ".",
						thousand: ",",
						precision: 2,
						format: "%s%v",
					});
				},
				genrate_uuid() {
					return UUID.v1();
				},
				to_fixed(number, digit = 2) {
					return Number(number.toFixed(digit));
				},
				validateForm(formRef) {
					this.$store.commit(SET_ERROR, []);
					const formErrors = [];
					const { inputs } = formRef;
					for (let i = 0; i < inputs.length; i++) {
						const { errorBucket } = inputs[i];
						for (let z = 0; z < errorBucket.length; z++) {
							const errorMessage = capitalize(toLower(errorBucket[z]));
							if (errorMessage) {
								formErrors.push({
									model: true,
									message: errorMessage,
								});
							}
						}
					}
					return formErrors;
				},
				goBack() {
					this.$router.go(-1);
				},
				backToListing(url) {
					this.$router.push({
						name: url,
					});
				},
				async logError(error) {
					try {
						console.warn(error);
					} catch (error) {
						console.warn(error);
					}
				},
				stringUnique() {
					let result = "";
					let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
					let charactersLength = characters.length;
					for (let i = 0; i < 10; i++) {
						result += characters.charAt(Math.floor(Math.random() * charactersLength));
					}
					return result;
				},
				eachDayOfInterval({ start, end }) {
					let pre_start = new Date(start);
					let pre_end = new Date(end);

					const output = [];

					while (pre_start < pre_end) {
						output.push(MomentJS(pre_start).format("YYYY-MM-DD"));
						let new_date = pre_start.setDate(pre_start.getDate() + 1);
						pre_start = new Date(new_date);
					}

					return output;
				},
				getPermission(key) {
					/*const is_owner = !!ObjectPath.get(this.authUser, "is_owner", 0);
					const is_super_admin = !!ObjectPath.get(this.authUser, "is_super_admin", 0);
					if (is_owner || is_super_admin) {
						return true;
					}*/
					let module_key = key.replace("eng-contractor", "member");

					return includes(this.permissions, module_key);
				},
				limitDecimal($event, value) {
					if ($event.keyCode === 45 || $event.keyCode === 69 || $event.keyCode === 101) {
						$event.preventDefault();
					}
					if (value) {
						let string = value.toString();
						if (
							(string != null && string.indexOf(".") > -1 && string.split(".")[1].length > 1) ||
							value > 99999999999
						) {
							$event.preventDefault();
						}
					}
				},
				limitDecimal1($event, value) {
					if ($event.keyCode === 45 || $event.keyCode === 69 || $event.keyCode === 101) {
						$event.preventDefault();
					}
					if (value) {
						if (value > 99999999999) {
							$event.preventDefault();
						}
					}
				},
				convert_base_64(file) {
					return new Promise((resolve, reject) => {
						try {
							const reader = new FileReader();
							reader.addEventListener(
								"load",
								function () {
									resolve(reader.result);
								},
								false
							);
							reader.readAsDataURL(file);
						} catch (error) {
							reject(error);
						}
					});
				},
				validateBarcode(barcode, type, param) {
					const _this = this;
					if (!barcode) {
						return false;
					}
					return new Promise((resolve, reject) => {
						_this.pageLoading = true;
						_this.barcodeError = null;
						ValidateBarcode(`validate-barcode/${barcode}/${type}`, param)
							.then((output) => {
								if (output.exists) {
									_this.barcodeError = `${barcode} already exists`;
									resolve(true);
								} else {
									resolve(false);
								}
							})
							.catch((error) => {
								reject(error);
							})
							.finally(() => {
								_this.pageLoading = false;
							});
					});
				},
				async genrateBarcode(type) {
					this.barcodeLoading = true;
					const { barcode } = await GenrateBarcode(type);
					this.barcodeLoading = false;
					return barcode;
				},
				lower_case(string) {
					return lowerCase(string);
				},
				// if (event.type == "tasks") {
				// 	return "orange";
				// } else if (event.type == "milestones") {
				// 	return "cyan darken-4";
				// } else if (event.type == "projects") {
				// 	return "cyan darken-4";
				// } else if (event.type == "purchase-orders") {
				// 	return "amber lighten-1";
				// } else if (event.type == "quotations") {
				// 	return "orange accent-2";
				// }
				getModuleColor(param) {
					switch (this.lower_case(param)) {
						case "all":
							return "indigo darken-3";
						case "tasks":
							return "orange";
						case "milestones":
							return "cyan darken-4";
						case "projects":
							return "orange darken-4";
						case "purchase-orders":
							return "amber lighten-1";
						case "quotations":
							return "green accent-4";
						default:
							return "blue";
					}
				},
				getStatusColor(param) {
					switch (this.lower_case(param)) {
						case "all":
							return "teal lighten-2";
						case "draft":
							return "blue";
						case "approved":
							return "green";
						case "accepted":
							return "green";
						case "completed":
							return "green";
						case "closed":
							return "red accent-2";
						case "rejected":
							return "red";
						case "cancelled":
							return "yellow darken-1";
						case "partially received":
							return "orange";
						case "pending":
							return "amber darken-4";
						case "in progress":
							return "orange lighten-1";
						case "inprogress":
							return "orange";
						case "fully received":
							return "teal";
						case "received":
							return "cyan darken-1";

						case "force closed":
							return "red";
						case "Rejected":
							return "red darken-4";
						case "sent for approval":
							return "orange darken-2";
						case "overdue":
							return "red ligten-1";
						default:
							return "blue";
					}
				},
			},
			mounted() {
				this.today = MomentJS().format("YYYY-MM-DD");
				this.uniqFieldId = this.stringUnique();
			},
			errorCaptured(err, vm, info) {
				this.logError({ err, vm, info });
			},
			computed: {
				...mapGetters(["permissions", "authUser"]),
			},
		});
	}
}

export default new MainSetting();
